<template>
  <div>
    <template v-if="isEntryRoute || isDetailRouteWithoutParameter">
      <entity-entry-header :entityKey="eCurrent.key" :title="$t(`views.${eCurrent.key}.title`)"/>
    </template>

    <!-- if this is active route (e.g. /productpopularity) -->
    <template v-if="isEntryRoute">
      <entity-entry-table :entityKey="eCurrent.key" :tableSettings="table" searchable>
        <template #cell(salesamount)="data">
          {{  data.cell.value }}
        </template>
        <template #cell(basketaddamount)="data">
          {{  data.cell.value }}
        </template>
        <template #cell(wishlistaddamount)="data">
          {{  data.cell.value }}
        </template>
        <template #cell(detailviewamount)="data">
          {{  data.cell.value }}
        </template>
        <template #cell(customboost)="data">
          {{  data.cell.value }}
        </template>
        <template #cell(voigtranking)="data">
          {{  data.cell.value }}
        </template>
        <template #cell(moonshotboost)="data">
          {{  data.cell.value }}
        </template>
        <template #cell(lastmodified)="data">
          {{ data.cell.value ? $d(new Date(data.cell.value), 'compact') : '-' }}
        </template>
      </entity-entry-table>
    </template>

    <!-- if subroute is active route (e.g. /productpopularity/detail/:productId) -->
    <section v-else-if="isDetailRouteWithParameter">
      <entity-detail-header :entityKey="eCurrent.key" :entityId="eCurrent.id">
        <template #default="data">
          <dl class="detailheader-list">
            <dt class="sr-only">{{ $t('components.entity.detailheader.name.label') }}</dt>
            <dd><h1>{{ data.label }}</h1></dd>
          </dl>
        </template>
      </entity-detail-header>

      <router-view/>
    </section>

    <!-- if subroute is active route (e.g. /productpopularity/add) -->
    <router-view v-else-if="isDetailRouteWithoutParameter"/>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_UNKNOWN } from '@/constants'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityEntryHeader from '@/components/entity/EntryHeader'
import EntityEntryTable from '@/components/entity/EntryTable'
import EntityDetailHeader from '@/components/entity/DetailHeader'

const ENTITY_KEY = 'productpopularities'

export default {
  name: 'Productpopularities',
  mixins: [MixinEntityBase],
  components: {
    EntityEntryHeader,
    EntityEntryTable,
    EntityDetailHeader
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            sorting: [CLASS_TABLE_FIELD_KEY_UNKNOWN],
            sortable: [],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            class: {},
            thClass: {}
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      }
    }
  },
  computed: {
    isEntryRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    isDetailRouteWithParameter () {
      return this.$route.matched[this.$route.matched.length - 1].path.indexOf(`/:${this.eCurrent.paramKey}`) >= 0
    },
    isDetailRouteWithoutParameter () {
      return !this.isEntryRoute && !this.isDetailRouteWithParameter
    }
  },
  methods: {
    tableEntitiesMapper (entities, definition) {
      return entities
        .map(e => Object.assign({}, e, {
          $routerLink: {
            name: `${this.$options.name}.Detail`,
            params: {
              [this.eCurrent.paramKey]: e.id
            }
          }
        }))
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    }
  },
  created () {
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
